/** @jsxImportSource react */
import { normJsonApi } from '@common/helpers/jsonApi';
import { useMapContext } from '@react/ReactGoogleMap';
import {
  filterLinkPath,
  getSearchParamFromPlacesResponse,
} from '@react/search/helpers';
import { usePrefetch, useQuery } from '@react/services/api';
import { useUser } from '@react/services/auth';
import { eventNames, useEvents } from '@react/services/events';
import {
  LOCATION_CURRENT_LATITUDE,
  LOCATION_CURRENT_LONGITUDE,
} from '@sly/core/constants/location';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useReducer } from 'react';
import { host, isBrowser, isDev } from 'sly/config';

const initialState = {
  textValue: '',
  isTextboxInFocus: false,
  selectedSuggestion: undefined,
  locationSuggestions: [],
  communitySuggestions: [],
  historySuggestions: (() => {
    if (
      typeof window !== 'undefined' &&
      localStorage.getItem('searchHistory')
    ) {
      try {
        return JSON.parse(localStorage.getItem('searchHistory'));
      } catch (e) {
        console.error('Failed to parse searchHistory from localStorage:', e);
        return [];
      }
    }
    return [];
  })(),
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'setTextBoxInFocus':
      return { ...state, isTextboxInFocus: action.payload };
    case 'reset':
      return {
        // ...state,
        isTextboxInFocus: true,
        textValue: '',
        selectedSuggestion: undefined,
        locationSuggestions: [],
        communitySuggestions: [],
        historySuggestions: state?.historySuggestions || [],
      };

    case 'setSelectedSuggestion':
      return { ...state, selectedSuggestion: action.payload };

    case 'setTextValue':
      return { ...state, textValue: action.payload };

    case 'updateExistingSuggestions': {
      const prevSuggestions =
        (state.suggestions && [...state.suggestions]) || [];
      return {
        ...state,
        suggestions:
          action.payload.update === 'prepend'
            ? [...action.payload.suggestions, ...prevSuggestions]
            : [...prevSuggestions, ...action.payload.suggestions],
      };
    }

    case 'setLocationSuggestions':
      return { ...state, locationSuggestions: action.payload };

    case 'setCommunitySuggestions':
      return { ...state, communitySuggestions: action.payload };

    case 'setHistorySuggestions':
      return { ...state, historySuggestions: action.payload };

    case 'resetSuggestions':
      return {
        ...state,
        locationSuggestions: [],
        communitySuggestions: [],
      };

    default:
      throw new Error(`${action.type} not a valid action`);
  }
};

const useSearchBox = ({
  address,
  onCurrentLocation,
  onBlur,
  eventProps,
  toc,
  onLocationSearch,
  includeAllTypes,
  include,
  onTextChange,
  types,
  disableGoogleSearch,
}) => {
  const { events } = useEvents();
  const { user } = useUser();
  // const router = useRouter();

  const maps = useMapContext();

  const {
    requestInfo: { normalized: uuidAux },
  } = usePrefetch(
    'getUuidAux',
    {
      id: user?.uuidAux?.id || 'dummyUuidAuxId',
    },
    {
      shouldBail: !user?.uuidAux?.id,
    }
  );

  const initializer = (initialValue = initialState) => {
    if (address) {
      return {
        ...initialValue,
        textValue: address,
      };
    }
    return initialValue;
  };

  const getAddresses = useQuery('getAddresses');
  const getSearch = useQuery('getSearch');

  const [state, dispatch] = useReducer(
    searchReducer,
    initialState,
    initializer
  );

  const {
    selectedSuggestion,
    textValue,
    isTextboxInFocus,
    locationSuggestions,
    communitySuggestions,
    historySuggestions,
  } = state;

  const searchForLatLong = ({ coords }) => {
    if (coords.latitude && coords.longitude) {
      const query = {
        'filter[geo]': `${coords.latitude},${coords.longitude}`,
      };
      getAddresses(query).then((resp) => {
        const addresses = normJsonApi(resp);
        if (onCurrentLocation) {
          onCurrentLocation(addresses, coords);
          if (addresses?.[0]?.city && addresses?.[0]?.state) {
            dispatch({
              type: 'setTextValue',
              payload: `${addresses?.[0]?.city}, ${addresses?.[0]?.state}`,
            });
          }
        }
      });
    }
  };

  const handleCurrentLocationClick = () => {
    // SlyEvent.getInstance().sendEvent({
    //   action: 'select', category: 'searchOption', label: 'currentLocation',
    // });

    if (navigator.geolocation) {
      const savedLatitude = localStorage.getItem(LOCATION_CURRENT_LATITUDE);
      const savedLongitude = localStorage.getItem(LOCATION_CURRENT_LONGITUDE);

      navigator.geolocation.watchPosition(
        ({ coords }) => {
          localStorage.setItem(LOCATION_CURRENT_LATITUDE, coords.latitude);
          localStorage.setItem(LOCATION_CURRENT_LONGITUDE, coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // SlyEvent.getInstance().sendEvent({
            //   action: 'revoke-permission', category: 'searchOption', label: 'currentLocation',
            // });
            localStorage.removeItem(LOCATION_CURRENT_LATITUDE);
            localStorage.removeItem(LOCATION_CURRENT_LONGITUDE);
            alert(
              'There is no location support on this device or it is disabled. Please check your settings.'
            );
          }
        }
      );

      if (savedLatitude && savedLongitude) {
        searchForLatLong({
          coords: {
            latitude: savedLatitude,
            longitude: savedLongitude,
          },
        });
      } else {
        navigator.geolocation.getCurrentPosition(searchForLatLong);
      }
    } else {
      // SlyEvent.getInstance().sendEvent({
      //   action: 'no-support', category: 'searchOption', label: 'currentLocation',
      // });
      alert(
        'There is no location support on this device or it is disabled. Please check your settings.'
      );
    }
  };

  const handleTextboxBlur = () => {
    if (onBlur) {
      onBlur();
    }
    dispatch({ type: 'setTextBoxInFocus', payload: false });
  };

  const handleTextboxFocus = () => {
    dispatch({ type: 'reset' });

    events.track(eventNames.FieldFocused, {
      type: 'input',
      name: 'search',
      form: 'search',
      ...eventProps,
    });
  };

  const getAutocomplete = useCallback(
    (query) => {
      if (query) {
        return getSearch(query).then((resp) => {
          const matches = normJsonApi(resp);
          dispatch({ type: 'setCommunitySuggestions', payload: matches });
        });
      }

      return Promise.resolve();
    },
    [getSearch]
  );

  const handleSelect = (suggestion) => {
    // const { name, resourceType } = suggestion;

    // SlyEvent.getInstance().sendEvent({
    //   action: 'select', category: 'searchOption', label: resourceType, value: name,
    // });

    handleTextboxBlur();
    dispatch({ type: 'setSelectedSuggestion', payload: suggestion });

    if (suggestion.resourceType === 'GoogleCity') {
      const newHistorySuggestions = [
        { ...suggestion, resourceType: 'History' },
        ...(historySuggestions || []),
      ];

      dispatch({
        type: 'setHistorySuggestions',
        payload: newHistorySuggestions,
      });

      localStorage.setItem(
        'searchHistory',
        JSON.stringify(newHistorySuggestions)
      );
    }

    if (
      suggestion.resourceType === 'GoogleCity' ||
      suggestion.resourceType === 'History'
    ) {
      maps
        .getGeocode({
          placeId: suggestion.place_id,
        })
        .then(async (responses = []) => {
          const searchParams = getSearchParamFromPlacesResponse(
            responses[0],
            toc
          );
          const path = filterLinkPath(searchParams);

          suggestion.url = path;
          suggestion.searchParams = searchParams;
          // todo: for plus-map:
          suggestion.geometry = !!responses.length && responses[0]?.geometry;
          if (onLocationSearch) {
            await onLocationSearch(suggestion);
          } else {
            if (isBrowser) {
              window.location.href = `${host}${path}`;
            }
            // router.replace(path)
          }
        })
        .catch((e) => {
          if (isDev) console.error(e);
        });
    } else if (onLocationSearch) {
      onLocationSearch(suggestion);
    } else if (suggestion.action === 'redirect') {
      window.location.href = suggestion.url;
    }
    events.track(eventNames.FormSubmitted, { form: 'search', ...eventProps });
  };

  const handleSearchButtonClick = () => {
    if (
      !selectedSuggestion &&
      !locationSuggestions.length &&
      !communitySuggestions.length
    ) {
      return;
    }
    const suggestion =
      selectedSuggestion ||
      [...locationSuggestions, ...communitySuggestions][0];

    if (suggestion) {
      handleSelect(suggestion);
    }
  };

  const handleKeyDown = useCallback(
    (textValue) => {
      if (!textValue) {
        dispatch({ type: 'resetSuggestions' });
      }

      const promises = [];
      if (!disableGoogleSearch) {
        promises.push(
          maps
            .getPlacePredictions(textValue, includeAllTypes, types)
            .then((results) => {
              const googleSuggestions = results.map((s) => ({
                ...s,
                id: s.place_id,
                name: s.description,
                displayText: s.description,
                resourceType: 'GoogleCity',
                action: 'redirect',
              }));

              // dispatch({ type: 'updateExistingSuggestions', payload: { update: 'prepend', suggestions: googleSuggestions} });
              dispatch({
                type: 'setLocationSuggestions',
                payload: googleSuggestions,
              });

              events.track(eventNames.TextEntered, {
                text: textValue,
                field: 'search',
                form: 'search',
                ...eventProps,
              });
            })
            .catch((e) => {
              if (isDev) console.error(e);
            })
        );
      }

      let query;
      if (include) {
        query = {
          'filter[query]': textValue,
          include,
        };
      }

      if (include !== '') {
        promises.push(getAutocomplete(query));
      }

      return Promise.all(promises);
    },
    [
      disableGoogleSearch,
      eventProps,
      events,
      getAutocomplete,
      include,
      includeAllTypes,
      types,
      maps,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedKeyDown = useCallback(debounce(handleKeyDown, 150), [maps]);

  const handleOnChange = (e) => {
    if (!e.target.value) {
      dispatch({ type: 'reset' });
    }

    if (onTextChange) {
      onTextChange(e.target.value);
    }

    dispatch({ type: 'setTextValue', payload: e.target.value });
    if (textValue !== e.target.value) {
      debouncedKeyDown(e.target.value);
    }
    if (!isTextboxInFocus) {
      dispatch({ type: 'setTextBoxInFocus', payload: true });
    }
  };

  useEffect(() => {
    if (selectedSuggestion) {
      if (include === 'zip') {
        selectedSuggestion?.structured_formatting?.main_text &&
          dispatch({
            type: 'setTextValue',
            payload: selectedSuggestion?.structured_formatting?.main_text,
          });
      }
      selectedSuggestion.resourceType === 'City'
        ? dispatch({
            type: 'setTextValue',
            payload: selectedSuggestion.displayText,
          })
        : dispatch({ type: 'setTextValue', payload: selectedSuggestion.name });
    }
  }, [selectedSuggestion]);

  return {
    state,
    dispatch,
    searchForLatLong,
    handleCurrentLocationClick,
    handleTextboxBlur,
    handleTextboxFocus,
    getAutocomplete,
    handleSelect,
    handleSearchButtonClick,
    handleKeyDown,
    handleOnChange,
    componentProps: {
      value: textValue,
      disabled: false,
      suggestions: [
        ...locationSuggestions,
        ...communitySuggestions,
        ...historySuggestions,
      ],
      defaultValue: address,
      onSelect: handleSelect,
      onFocus: handleTextboxFocus,
      onBlur: handleTextboxBlur,
      onChange: handleOnChange,
      isTextboxInFocus: isTextboxInFocus,
      onCurrentLocationClick:
        onCurrentLocation && !disableGoogleSearch
          ? handleCurrentLocationClick
          : null,
      onSearchButtonClick: handleSearchButtonClick,
      type: include === 'zip' ? 'number' : 'text',
    },
  };
};

export default useSearchBox;
