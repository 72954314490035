import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M9.78695 14.1054L3.2402 11.4565C2.99564 11.3612 2.81539 11.2141 2.69945 11.0152C2.5835 10.8163 2.52553 10.6112 2.52553 10.4C2.52553 10.1888 2.58767 9.98368 2.71195 9.78476C2.83622 9.58585 3.02064 9.43875 3.2652 9.34346L18.962 3.47009C19.1899 3.37481 19.4094 3.35615 19.6207 3.41411C19.8319 3.47208 20.0121 3.57571 20.1614 3.72499C20.3107 3.87426 20.4153 4.05451 20.4753 4.26574C20.5352 4.47697 20.5176 4.69654 20.4223 4.92444L14.5489 20.6272C14.4536 20.8717 14.3065 21.0562 14.1076 21.1804C13.9087 21.3047 13.7036 21.3668 13.4924 21.3668C13.2812 21.3668 13.0761 21.3089 12.8772 21.1929C12.6783 21.077 12.5312 20.8967 12.4359 20.6522L9.78695 14.1054ZM13.4424 17.1087L17.4147 6.47174L6.7837 10.45L11.5522 12.3342L13.4424 17.1087Z"/>
</svg>`;

const Navigation = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='navigation' svg={svg} {...props} />
  )
);

Navigation.displayName = 'NavigationIcon';

export default Navigation;
