import { assetPath } from '@common/lib/path';
import { CURRENT_YEAR } from './year';

export const SENIORLY_AWARD_YEAR_COMMUNITIES = CURRENT_YEAR;
export const SENIORLY_AWARD_YEAR_AGENTS = 2025;
export const SENIORLY_AWARD_IMAGE_SRC = assetPath(
  `uploads/react-assets/best-of-badge.png`
);

export const SENIORLY_AWARD_IMAGE_SRC_AGENTS = assetPath(
  `uploads/react-assets/top-advisor-${SENIORLY_AWARD_YEAR_AGENTS}.png`
);

export const SENIORLY_AWARD_IMAGE_SRC_AGENTS_PLATINUM = assetPath(
  `uploads/react-assets/platinum-advisor-${SENIORLY_AWARD_YEAR_AGENTS}.png`
);

export const AWARD_MARKETING_PAGE_LINK = `/company/best-of-senior-living-awards-${SENIORLY_AWARD_YEAR_COMMUNITIES}`;
