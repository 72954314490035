/** @jsxImportSource react */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsxm from '@common/lib/clsxm';
import HomeLarge from '@sly/icons/react/HomeLarge';
import Location from '@sly/icons/react/Location';
import Navigation from '@sly/icons/react/Navigation';
import Search from '@sly/icons/react/Search';
import Time from '@sly/icons/react/Time';
import { arrayOf, bool, func, object, oneOf, string } from 'proptypes';
import React from 'react';

import {
  searchBoxIcon,
  searchBoxInput,
  searchBoxSuggestions,
  searchBoxWrapper,
} from './classes';

const Icon = ({ name }) => {
  switch (name) {
    case 'community-size-large':
      return <HomeLarge size='m' />;
    case 'location':
      return <Location size='m' />;
    default:
      return <Time size='m' />;
  }
};

const groupSuggestions = (suggestions) =>
  suggestions.reduce((acc, curr) => {
    if (!acc[curr.resourceType]) acc[curr.resourceType] = [];
    acc[curr.resourceType].push(curr);
    return acc;
  }, {});

const GROUP_LABELS = {
  City: 'Locations',
  Zipcode: 'Locations',
  GoogleCity: 'Locations',
  Community: 'Communities',
  PartnerAgent: 'Agents',
  History: 'Recent search',
};

const GROUP_ICONS = {
  City: 'location',
  Zipcode: 'location',
  GoogleCity: 'location',
  Community: 'community-size-large',
  PartnerAgent: 'user',
  History: 'time',
};

const GROUP_LIMITS = {
  City: 5,
  Zipcode: 5,
  GoogleCity: 5,
  Community: 5,
  PartnerAgent: 5,
  History: 5,
};

const SearchLinks = ({ gps, onSelect, className, isBrand = false }) => {
  if (!gps) return <></>;

  const DisplayItem = ({ suggestion }) => {
    switch (suggestion.resourceType) {
      case 'City':
      case 'Zipcode':
        return suggestion.displayText;
      case 'Community':
        return (
          <div>
            <p className='font-b-s '>{suggestion?.name}</p>
            <p className='font-b-s text-slate-lighter-40'>
              {suggestion?.info?.addressString}
            </p>
          </div>
        );
      default:
        return suggestion.name;
    }
  };

  return (
    <div>
      {Object.keys(gps).map((k, index) => (
        <div
          className={clsxm(
            index > 0 ? (isBrand ? 'pt-xs' : 'pt-m') : '',
            index < Object.keys(gps).length - 1 && 'border-b',
            className
          )}
          key={k}
          data-tp-id='gen-div-33e540f8-d9d2-499e-8d85-78591992f360'
        >
          <div
            className={clsxm(
              'font-b-s text-slate-lighter-40',
              isBrand ? 'px-0' : 'px-4'
            )}
            icon={GROUP_ICONS[k]}
            data-tp-id='gen-div-212b8230-74d0-4787-9ed8-21a6c64d67e9'
          >
            <div
              className={clsxm(
                'flex items-center gap-2',
                isBrand && 'gap-4 py-2'
              )}
              data-tp-id='gen-div-296cb86c-bb6e-4f42-a99e-b72946fea54a'
            >
              <Icon
                name={GROUP_ICONS[k]}
                data-tp-id='gen-Icon-4d1be6a6-9e7e-4724-9157-6456962506dc'
              />
              {GROUP_LABELS[k]}
            </div>
          </div>
          {gps[k].slice(0, GROUP_LIMITS[k]).map((suggestion) => (
            <div
              className={clsxm(
                'font-b-s w-full cursor-pointer py-s text-slate-lighter-20 hover:bg-viridian-lighter-90',
                suggestion.active ? 'bg-gray-600' : 'bg-white',
                isBrand ? 'pl-10' : 'pl-12'
              )}
              onMouseDown={() => onSelect(suggestion)}
              key={suggestion.id}
              data-tp-id='gen-div-d031479d-322d-48aa-9e82-0113fe81c7af'
            >
              <div
                className='mr-xxl'
                data-tp-id='gen-div-00718741-2cf7-4107-8bcc-389e67e8451f'
              >
                <DisplayItem suggestion={suggestion} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const SearchBox = ({
  value,
  onChange,
  onSelect,
  onFocus,
  isTextboxInFocus,
  onSearchButtonClick,
  onCurrentLocationClick,
  onBlur,
  placeholder,
  readOnly,
  suggestions,
  showSearchIcon,
  invalid,
  className,
  inputClassName,
  intent = 'brand',
  size = 'sm',
}) => {
  const historySuggestions = suggestions
    .filter((suggestion) => suggestion.resourceType === 'History')
    .filter(
      (suggestion, index, self) =>
        index === self.findIndex((s) => s.id === suggestion.id)
    );
  const otherSuggestions = suggestions.filter(
    (suggestion) => suggestion.resourceType !== 'History'
  );

  const hps = groupSuggestions(historySuggestions);
  const gps = groupSuggestions(otherSuggestions);

  const hasHistorySuggestions = historySuggestions?.length > 0;
  const hasOtherSuggestions = otherSuggestions?.length > 0;

  const isBrand = intent === 'brand';
  const isLarge = size === 'lg';

  const ifCurrentEnabled = onCurrentLocationClick;
  const ifCurrentDisabled = !onCurrentLocationClick && hasOtherSuggestions;

  const showSuggestions =
    isTextboxInFocus && (ifCurrentEnabled || ifCurrentDisabled);

  return (
    <div
      className={clsxm('relative', className)}
      data-tp-id='gen-div-4735c3f6-0c58-4262-a1e4-65b845f18614'
    >
      <div
        className={clsxm(searchBoxWrapper({ intent, size }), inputClassName)}
        data-tp-id='gen-div-cd6f5b97-89d1-4139-8b8c-f247b84560b7'
      >
        <input
          className={clsxm(
            searchBoxInput({ intent, size, active: isTextboxInFocus })
          )}
          type='text'
          placeholder={placeholder}
          disabled={false}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          onChange={onChange}
          invalid={invalid}
          value={value}
          data-tp-id='gen-input-43ac410b-1f2a-46e6-86d5-d189b5a85d5b'
        />
        {showSearchIcon && (
          <button
            className={clsxm(
              searchBoxIcon({ intent, size, active: isTextboxInFocus })
            )}
            aria-label='search'
            data-tp-id='gen-button-e2b49cf4-5dd4-4351-ad97-b9e0e553e9d1'
          >
            <Search
              size={!isBrand ? 'm' : isLarge ? 'xl' : 'l'}
              className={clsxm(
                !isBrand
                  ? 'mr-m text-viridian-base'
                  : 'mr-2 h-8 w-8 rounded-full bg-viridian-base p-2 text-white'
              )}
              onClick={onSearchButtonClick}
            />
          </button>
        )}
      </div>
      {showSuggestions && (
        <div
          className={clsxm(searchBoxSuggestions({ intent, size }))}
          data-tp-id='gen-div-15a48b96-4d33-423e-91aa-d766f59275e3'
        >
          {/*
            use mouseDown instead of onClick as the onClick which is triggered after mouse button is release will trigger blur of textbox
            that will by the time hide the suggestions dropdown
          */}
          {onCurrentLocationClick && !hasOtherSuggestions && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <>
              <div
                className={clsxm(
                  'font-b-s flex w-full cursor-pointer items-center hover:bg-viridian-lighter-90',
                  isBrand
                    ? 'py-2 text-viridian-base'
                    : 'py-2 px-4  text-slate-lighter-40',
                  hasHistorySuggestions && 'border-b'
                )}
                onMouseDown={onCurrentLocationClick}
                data-tp-id='gen-div-8310632e-d23d-46e7-a01a-17159f21668d'
              >
                <Navigation
                  size='m'
                  className={clsxm(
                    'mr-2 inline',
                    isBrand
                      ? 'mr-4 text-viridian-base'
                      : 'text-slate-lighter-40'
                  )}
                />
                Current location
              </div>

              {hasHistorySuggestions && (
                <SearchLinks
                  gps={hps}
                  onSelect={onSelect}
                  isBrand={isBrand}
                  className={!isBrand && 'py-2'}
                />
              )}
            </>
          )}

          {hasOtherSuggestions && (
            <SearchLinks gps={gps} onSelect={onSelect} isBrand={isBrand} />
          )}
        </div>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  layout: oneOf(['header', 'homeHero']),
  value: string.isRequired,
  onChange: func.isRequired,
  onSelect: func.isRequired,
  onSearchButtonClick: func,
  onFocus: func,
  isTextboxInFocus: bool,
  onCurrentLocationClick: func,
  onBlur: func,
  onKeyDown: func,
  placeholder: string,
  readOnly: bool,
  suggestions: arrayOf(object).isRequired,
  showSearchIcon: bool,
  type: string,
  invalid: bool,
};

SearchBox.defaultProps = {
  layout: 'header',
  placeholder: 'Search by city, state, zip',
  value: '',
  defaultValue: '',
  suggestions: [],
  showSearchIcon: true,
  type: 'text',
};

export default SearchBox;
