import { cva, VariantProps } from 'class-variance-authority';

export const searchBoxInput = cva(
  'm-0 block w-full text-ellipsis rounded-r-none border border-r-0 border-slate-lighter-90 outline-none',
  {
    variants: {
      intent: {
        brand: 'shadow-subtle text-slate-lighter-40 rounded-full',
        default: 'text-slate-lighter-20 p-s py-2 rounded h-11',
      },
      size: {
        lg: 'p-2 pl-6 h-14',
        sm: 'p-2 pl-4 h-11',
        default: '',
      },
      active: {
        true: 'border-viridian-base',
        false: '',
      },
    },
  }
);

export const searchBoxWrapper = cva('flex bg-white', {
  variants: {
    intent: {
      brand: 'rounded-full',
      default: 'rounded',
    },
    size: {
      lg: '',
      sm: '',
      default: '',
    },
  },
});

export const searchBoxIcon = cva(
  'flex items-center justify-center rounded-l-none border border-l-0 border-slate-lighter-90',
  {
    variants: {
      intent: {
        brand: 'rounded-full',
        default: 'rounded',
      },
      size: {
        lg: 'h-14 w-14',
        sm: 'h-11 w-11',
        default: '',
      },
      active: {
        true: 'border-viridian-base',
        false: '',
      },
    },
  }
);

export const searchBoxSuggestions = cva(
  'absolute left-0 right-0 z-[10002] border-[0.062rem] border-slate-lighter-90 bg-white overflow-y-auto max-h-[540px]',
  {
    variants: {
      intent: {
        brand: 'overflow-hidden rounded-3xl shadow-subtle mt-1 px-6 py-4',
        default: 'shadow-sm py-s pl-m',
      },
      size: {
        lg: '',
        sm: '',
        default: '',
      },
    },
  }
);

export type SearchBoxVariant = VariantProps<typeof searchBoxInput>;
