import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

interface DateParams {
  day?: number;
  month: number;
  year: number;
}

export interface Holiday {
  name: string;
  alsoObservedAs?: string;
  date: Date;
  dateString: string;
  getFormattedDate: (format: string) => string;
}

export interface HolidayOptions {
  shiftSaturdayHolidays?: boolean;
  shiftSundayHolidays?: boolean;
}

interface IsHolidayOptions extends HolidayOptions {
  utc?: boolean;
}

const getDateFor = ({ day = 1, month, year }: DateParams): dayjs.Dayjs =>
  dayjs(`${year}-${month}-${day}`, 'YYYY-M-D');

const getNthDayOf = (
  n: number,
  day: number,
  month: number,
  year: number
): dayjs.Dayjs => {
  let result = dayjs(getDateFor({ month, year })).day(day);

  if (result.month() !== month - 1) {
    result = result.add(1, 'week');
  }

  result = result.add(n - 1, 'week');
  return result;
};

const getLastDayOf = (
  day: number,
  month: number,
  year: number
): dayjs.Dayjs => {
  const daysInMonth = dayjs(getDateFor({ month, year })).daysInMonth();
  const lastDayOfMonth = dayjs(`${year}-${month}-${daysInMonth}`, 'YYYY-M-D');

  let result = lastDayOfMonth.day(day);

  if (result.month() !== month - 1) {
    result = result.subtract(1, 'week');
  }

  return result;
};

export const allFederalHolidaysForYear = (
  year: number = new Date().getFullYear(),
  {
    shiftSaturdayHolidays = true,
    shiftSundayHolidays = true
  }: HolidayOptions = {}
): Holiday[] => {
  const holidays = [
    {
      name: `New Year's Day`,
      date: getDateFor({ day: 1, month: 1, year })
    },
    {
      name: `Birthday of Martin Luther King, Jr.`,
      date: getNthDayOf(3, 1, 1, year)
    },
    {
      name: `Washington's Birthday`,
      alsoObservedAs: "Presidents' Day",
      date: getNthDayOf(3, 1, 2, year)
    },
    {
      name: `Memorial Day`,
      date: getLastDayOf(1, 5, year)
    },
    {
      name: `Independence Day`,
      date: getDateFor({ day: 4, month: 7, year })
    },
    {
      name: `Labor Day`,
      date: getNthDayOf(1, 1, 9, year)
    },
    {
      name: `Columbus Day`,
      alsoObservedAs: "Indigenous Peoples' Day",
      date: getNthDayOf(2, 1, 10, year)
    },
    {
      name: `Veterans Day`,
      date: getDateFor({ day: 11, month: 11, year })
    },
    {
      name: `Thanksgiving Day`,
      date: getNthDayOf(4, 4, 11, year)
    },
    {
      name: `Christmas Day`,
      date: getDateFor({ day: 25, month: 12, year })
    }
  ];

  if (year > 2020) {
    holidays.splice(4, 0, {
      name: `Juneteenth National Independence Day`,
      date: getDateFor({ day: 19, month: 6, year })
    });
  }

  return holidays.map(holiday => {
    let date = dayjs(holiday.date);

    if (date.day() === 0 && shiftSundayHolidays) {
      date = date.add(1, 'day');
    }

    if (date.day() === 6 && shiftSaturdayHolidays) {
      date = date.subtract(1, 'day');
    }

    return {
      name: holiday.name,
      alsoObservedAs: holiday.alsoObservedAs,
      date: date.toDate(),
      dateString: date.format('YYYY-MM-DD'),
      getFormattedDate: (format: string) => date.format(format)
    };
  });
};

export const isAHoliday = (
  date: Date = new Date(),
  {
    shiftSaturdayHolidays = true,
    shiftSundayHolidays = true,
    utc = false
  }: IsHolidayOptions = {}
): boolean => {
  const newDate = utc ? dayjs.utc(date) : dayjs(date);
  const year = newDate.year();

  const shift = { shiftSaturdayHolidays, shiftSundayHolidays };
  const allForYear = allFederalHolidaysForYear(year, shift);
  const nextYear = allFederalHolidaysForYear(year + 1, shift);
  allForYear.push(nextYear[0]);

  return allForYear.some(
    holiday => holiday.dateString === newDate.format('YYYY-MM-DD')
  );
};

const getOneYearFromNow = (): Date => {
  const future = new Date();
  future.setUTCFullYear(future.getUTCFullYear() + 1);
  return future;
};

export const federalHolidaysInRange = (
  startDate: Date = new Date(),
  endDate: Date = getOneYearFromNow(),
  options: HolidayOptions = {}
): Holiday[] => {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const candidates: Holiday[] = [];
  for (let year = startYear; year <= endYear; year += 1) {
    candidates.push(...allFederalHolidaysForYear(year, options));
  }
  return candidates.filter(h => h.date >= startDate && h.date <= endDate);
};
