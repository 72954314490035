import Image from '@react/shared/components/Image';
import SearchBoxContainer from '@sly/frontend/react/search/components/SeachBoxContainer';
import { assetPath } from 'sly/lib/path';

const HomePageHeader = ({ onCurrentLocation }: { onCurrentLocation: any }) => {
  return (
    <section className='mt-8 mb-12 px-8'>
      <header className='relative z-50 mx-auto h-[480px] max-w-5xl self-stretch'>
        <Image
          src={assetPath('uploads/react-assets/home/Hero group (1).png')}
          alt='Seniorly homepage banner'
          width={1000}
          height={480}
          layout='raw'
          className='h-full w-full rounded-xl object-cover object-right'
        />

        <div className='absolute top-1/2 mx-8 mt-8 -translate-y-1/2 transform sm:mt-0 sm:ml-8'>
          <section className='w-full max-w-lg space-y-6'>
            <h1 className='font-t-xxl text-shadow text-white'>
              Find a senior living community you’ll love
            </h1>
            <SearchBoxContainer
              eventProps={{
                location: 'homepage - search box',
              }}
              onCurrentLocation={onCurrentLocation}
              include='community'
              placeholder='Search by city, state, ZIP code, community name'
              variant='main'
              size='lg'
            />
          </section>
        </div>
      </header>
    </section>
  );
};

export default HomePageHeader;
