import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M13.0897 11.5522V8.1196C13.0897 7.81085 12.9852 7.55205 12.7764 7.3432C12.5675 7.13435 12.3087 7.02993 12 7.02993C11.6913 7.02993 11.4325 7.13435 11.2236 7.3432C11.0147 7.55205 10.9103 7.81085 10.9103 8.1196V11.9571C10.9103 12.1104 10.9383 12.2566 10.9943 12.3958C11.0503 12.5351 11.1342 12.6604 11.2462 12.7718L14.4505 15.9761C14.6538 16.1794 14.9071 16.281 15.2103 16.281C15.5136 16.281 15.7688 16.1794 15.9761 15.9761C16.1833 15.7729 16.287 15.5176 16.287 15.2104C16.287 14.9031 16.1833 14.6459 15.9761 14.4386L13.0897 11.5522ZM12 22.2033C10.5848 22.2033 9.25691 21.9357 8.01637 21.4004C6.77584 20.8651 5.69675 20.1386 4.7791 19.2209C3.86145 18.3033 3.13497 17.2242 2.59967 15.9837C2.06437 14.7431 1.79672 13.4152 1.79672 12C1.79672 10.5848 2.06437 9.25694 2.59967 8.0164C3.13497 6.77587 3.86145 5.69678 4.7791 4.77913C5.69675 3.86148 6.77584 3.135 8.01637 2.5997C9.25691 2.0644 10.5848 1.79675 12 1.79675C13.4152 1.79675 14.7431 2.0644 15.9836 2.5997C17.2242 3.135 18.3032 3.86148 19.2209 4.77913C20.1385 5.69678 20.865 6.77587 21.4003 8.0164C21.9356 9.25694 22.2033 10.5848 22.2033 12C22.2033 13.4152 21.9356 14.7431 21.4003 15.9837C20.865 17.2242 20.1385 18.3033 19.2209 19.2209C18.3032 20.1386 17.2242 20.8651 15.9836 21.4004C14.7431 21.9357 13.4152 22.2033 12 22.2033ZM11.9999 19.9283C14.1967 19.9283 16.0673 19.1571 17.6117 17.6147C19.1561 16.0723 19.9282 14.2007 19.9282 12C19.9282 9.79931 19.1561 7.92775 17.6117 6.38535C16.0674 4.84297 14.1968 4.07178 12 4.07178C9.80328 4.07178 7.93271 4.84297 6.38832 6.38535C4.84394 7.92775 4.07175 9.79931 4.07175 12C4.07175 14.2007 4.84392 16.0723 6.38827 17.6147C7.93261 19.1571 9.80317 19.9283 11.9999 19.9283Z"/>
</svg>`;

const Time = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='time' svg={svg} {...props} />
  )
);

Time.displayName = 'TimeIcon';

export default Time;
